import { useEffect, useState } from "react";

import { getDesignPortfolioPosts } from "../contentful";

const promise = getDesignPortfolioPosts();

export default function useDesignPortfolioPosts() {
  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    promise.then((blogPosts) => {
      setPosts(blogPosts);
      setLoading(false);
    });
  }, []);

  return [posts, isLoading];
}
