import React, { useState, useEffect } from "react";
import pauseIcon from "../../assets/icons/pause.png";
import playIcon from "../../assets/icons/play.png";
import styles from "./Song.module.css";
const Song = ({
  title,
  index,
  file,
  startSong,
  stopSong,
  hoverPlayState,
  setHoverPlayState,
  playAudio,
  pauseAudio,
  setPauseAudio,
}) => {
  let myAudio = document.getElementById(`audio${title}`);

  const [renderPlayButtonHover, setRenderPlayButtonHover] = useState();
  const [renderPlayButton, setRenderPlayButton] = useState();
  const [renderPauseButton, setRenderPauseButton] = useState();
  const [renderIndex, setRenderIndex] = useState();

  useEffect(() => {
    // COUNTDOWN
    if (myAudio) {
      myAudio.addEventListener(
        "timeupdate",
        function () {
          let timeleft = document.getElementById(`timeleft${title}`),
            duration = parseInt(myAudio.duration),
            currentTime = parseInt(myAudio.currentTime),
            timeLeft = duration - currentTime,
            s,
            m;

          s = timeLeft % 60;
          m = Math.floor(timeLeft / 60) % 60;

          s = s < 10 ? "0" + s : s;
          m = m < 10 ? "0" + m : m;

          timeleft.innerHTML = m + ":" + s;

          if (s === "00" && m === "00") {
            setPauseAudio(null);
            setRenderPauseButton(null);
            setRenderIndex(<p className={styles.index}>{index}</p>);
          }
        },
        false
      );
    }
    // HOVER PLAY
    if (hoverPlayState === title && pauseAudio !== title) {
      setRenderPlayButtonHover(
        <img
          src={playIcon}
          alt="hover-play-icon"
          className={styles.playIcon}
          id={title}
          onClick={startSong}
          onMouseOver={(e) => {
            setHoverPlayState(title);
          }}
          onMouseOut={(e) => {
            setHoverPlayState("");
          }}
        />
      );
    } else {
      setRenderPlayButtonHover(null);
    }

    // PLAY
    if (playAudio === title) {
      setRenderPlayButton(
        <img
          src={playIcon}
          alt="play-icon"
          className={styles.playIcon}
          id={title}
          onClick={startSong}
        />
      );
    } else {
      setRenderPlayButton(null);
    }

    // PAUSE
    if (pauseAudio === title) {
      setRenderPauseButton(
        <img
          src={pauseIcon}
          alt="pause-icon"
          className={styles.pauseIcon}
          id={title}
          onClick={stopSong}
        />
      );
    } else {
      setRenderPauseButton(null);
    }

    // INDEX
    if (
      pauseAudio !== title &&
      playAudio !== title &&
      hoverPlayState !== title
    ) {
      setRenderIndex(<p className={styles.index}>{index}</p>);
    } else {
      setRenderIndex(null);
    }
  }, [playAudio, pauseAudio, hoverPlayState]);

  return (
    <div className={styles.container} key={index} id={title}>
      <span
        className={styles.placeHolder}
        onClick={startSong}
        id={title}
        onMouseOver={(e) => {
          setHoverPlayState(title);
        }}
        onMouseOut={(e) => {
          setHoverPlayState("");
        }}
      />
      <audio id={`audio${title}`}>
        <source src={file} type="audio/ogg" />
        <source src={file} type="audio/mpeg" />
      </audio>

      {/* HOVER PLAY */}
      {renderPlayButtonHover}

      {/* PLAY */}
      {renderPlayButton}

      {/* PAUSE */}
      {renderPauseButton}

      {/* INDEX */}
      {renderIndex}

      <h6 id={title}>{title}</h6>

      <p className={styles.length} id={`timeleft${title}`}>
        {/* {myAudio.duration} */}
      </p>
    </div>
  );
};

export default Song;
