import React from "react";
import styles from "./Loader.module.css";

const Loader = ({ variant }) => {
  const containerVariant =
    variant === "primary" ? styles.primaryContainer : styles.secondaryContainer;
  return (
    <div className={`${styles.container} ${containerVariant}`}>
      <span className={styles.loader} />
    </div>
  );
};

export default Loader;
