import React from "react";
import styles from "./Project.module.css";

const Project = ({ img, title, description, index, link }) => {
  return (
    <div className={styles.container} key={index}>
      <img src={img} alt="title" />
      <a href={link} target="_blank" rel="noreferrer">
        <div className={styles.textContainer}>
          <h5>{title}</h5>
          <small>{description}</small>
        </div>
      </a>
    </div>
  );
};

export default Project;
