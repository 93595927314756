import React from "react";
import Subscribe from "../../components/Subscribe/Subscribe";
import { Link } from "react-router-dom";
import usePosts from "../../custom-hooks/usePosts";
import getWritingsPageContent from "../../custom-hooks/getWritingsPageContent";
import styles from "./Writings.module.css";

const Writings = () => {
  const [posts, isLoading] = usePosts();
  const [content, isContentLoading] = getWritingsPageContent();
  
  return (
    <div className={styles.container}>
      {isContentLoading ? null : (
        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <h1>{content[0].fields.heading}</h1>
            <p>{content[0].fields.text}</p>
            <div className={styles.subscribeContainerMob}>
              <Subscribe />
            </div>
            <ul>
              {!isLoading ? (
                posts.map((post) => (
                  <Link
                    to={`writings/${post.fields.slug}`}
                    key={post.fields.slug}
                  >
                    <li>{post.fields.title}</li>
                  </Link>
                ))
              ) : (
                null
              )}
            </ul>
          </div>
          <div className={styles.subscribeContainerWeb}>
            <Subscribe />
          </div>
        </div>
      )}
    </div>
  );
};

export default Writings;
