import React, { useState, useEffect } from "react";
import { ReactComponent as BackArrow } from "../../assets/icons/backArrow.svg";
import Subscribe from "../../components/Subscribe/Subscribe";
import { useParams, useHistory } from "react-router-dom";
import MD from "react-markdown";
import useSinglePost from "../../custom-hooks/useSinglePost";
import styles from "./WritingDetail.module.css";
import Loader from "../../components/Loader/Loader";

const WritingDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const [post, isLoading] = useSinglePost(id);
  const [table, setTable] = useState([]);

  useEffect(() => {
    const postBody = document.getElementById("middleContainer");

    if (postBody) {
      const headings = postBody.querySelectorAll("h1, h2, h3");

      headings.forEach((heading, index) => {
        const { innerText } = heading;

        heading.setAttribute("id", innerText);

        if (heading.nodeName === "H3") {
          setTable((oldArray) => [
            ...oldArray,
            {
              hmm: innerText,
              sub: true,
            },
          ]);
        } else {
          setTable((oldArray) => [
            ...oldArray,
            {
              hmm: innerText,
              sub: false,
            },
          ]);
        }
      });
    }
  }, [isLoading]);

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader variant="primary" />
      ) : (
        <div className={styles.wrapper} id="wrapper">
          <div className={styles.leftWrapper}>
            <div className={styles.leftContainer}>
              <div
                className={styles.goBack}
                onClick={() => history.push("/writings")}
              >
                <BackArrow />
                <p>Back to list</p>
              </div>

              <ul className={styles.tableOfContents}>
                {table.map((item, i) => (
                  <li key={i} className={item.sub ? styles.subHeading : ""}>
                    <a href={`#${item.hmm}`}>{item.hmm}</a>
                  </li>
                ))}
              </ul>
            </div>

            <div className={styles.middleContainer} id="middleContainer">
              <div
                className={styles.goBackMob}
                onClick={() => history.push("/writings")}
              >
                <BackArrow />
                <p>Back to list</p>
              </div>
              <MD children={post.body} />
              <div className={styles.subscribeMob}>
                <Subscribe />
              </div>
            </div>
          </div>
          <div className={styles.rightContainer}>
            <Subscribe />
          </div>
        </div>
      )}
    </div>
  );
};

export default WritingDetail;
