import { useEffect, useState } from "react";

import { getWritingsPageContent } from "../contentful";

const promise = getWritingsPageContent();

export default function usePosts() {
  const [content, setContent] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    promise.then((content) => {
      setContent(content);
      setLoading(false);
    });
  }, []);

  return [content, isLoading];
}
