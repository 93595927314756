import React from "react";
import Layout from "../components/Layout/Layout";
import About from "../pages/About/About";
import Writings from "../pages/Writings/Writings";
import Projects from "../pages/Projects/Projects";
import Investments from "../pages/Investments/Investments";
import InvestmentsBlog from "../pages/InvestmentsBlog/InvestmentsBlog";
import Music from "../pages/Music/Music";
import DesignPortfolio from "../pages/DesignPortfolio/DesignPortfolio";
import WritingDetail from "../pages/WritingDetail/WritingDetail";
import DesignPortfolioBlog from "../pages/DesignPortfolioBlog/DesignPortfolioBlog";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const AppRoutes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Switch>
          <Route exact path="/" component={About} />
          <Route exact path="/writings" component={Writings} />
          <Route path="/writings/:id" component={WritingDetail} />
          <Route path="/projects" component={Projects} />
          <Route path="/music" component={Music} />
          <Route exact path="/investments" component={Investments} />
          <Route path="/investments/:id" component={InvestmentsBlog} />
          <Route exact path="/design-portfolio" component={DesignPortfolio} />
          <Route path="/design-portfolio/:id" component={DesignPortfolioBlog} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default AppRoutes;
