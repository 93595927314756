import React from "react";
import FAQs from "../../sections/About/FAQs/FAQs";
import Hero from "../../sections/About/Hero/Hero";
import styles from "./About.module.css";

const About = () => {
  return (
    <div className={styles.container}>
      <Hero />
      <FAQs />
    </div>
  );
};

export default About;
