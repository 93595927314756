import React, { useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import styles from "./Subscribe.module.css";

const Subscribe = ({ variant }) => {
  useEffect(() => {
    const inputTag = document.getElementsByTagName("input");
    Array.from(inputTag).map((e) => {
      e.attributes[1].textContent = "To subscribe, pls enter email";
    });
    const buttonTag = document.getElementsByTagName("button");
    Array.from(buttonTag).map((e) => {
      if (e.textContent === "Submit") {
        e.textContent = "Subscribe"
      }
    });
  });

  if (variant === "secondary") {
    return (
      <div className={`${styles.container} ${styles.secondaryContainer}`}>
        <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />
      </div>
    );
  } else {
    return (
      <div className={`${styles.container} ${styles.primaryContainer}`}>
        <MailchimpSubscribe url={process.env.REACT_APP_MAILCHIMP_URL} />
      </div>
    );
  }
};

export default Subscribe;
