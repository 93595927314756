import React from "react";
import EmailButton from "../../../components/EmailButton/EmailButton";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icons/location.svg";
import globeIcon from "../../../assets/icons/globe.png";
import { ReactComponent as Triangle } from "../../../assets/icons/triangle.svg";
import Fade from "react-reveal/Fade";
import styles from "./Hero.module.css";
import getAboutPageContent from "../../../custom-hooks/getAboutPageContent";

const Hero = () => {
  const [content, isLoading] = getAboutPageContent();

  if (isLoading) return null;

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.textContainer}>
          <Fade>
            <h1>A product generalist with speciality in design</h1>
            <p>{content[0].fields.HeroBio}</p>
          </Fade>
        </div>

        <div className={styles.details}>
          <div className={styles.socialContainer}>
            <EmailButton variant="primary" />
            <a href="https://twitter.com/adebayohassan">
              <TwitterIcon className={styles.twitterIcon} />
            </a>
            <a href="https://www.linkedin.com/in/yusufadehassan/">
              <LinkedinIcon className={styles.linkedinIcon} />
            </a>
          </div>
          <div className={styles.locationContainer}>
            <div className={styles.physical}>
              <LocationIcon className={styles.locationIcon} />
              <p>Physically: {content[0].fields.physicalLocation}</p>
            </div>
            <div className={styles.remote}>
              <img
                src={globeIcon}
                alt="globe-icon"
                className={styles.globeIcon}
              />
              <p>Remotely: {content[0].fields.remoteLocation}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.line}>
        <span />
        <div
          className={styles.circle}
          onClick={() => {
            window.scrollTo(0, document.body.scrollHeight);
          }}
        >
          <Triangle className={styles.triangle} />
        </div>
        <span />
      </div>
    </div>
  );
};

export default Hero;
