import React from "react";
import { ReactComponent as MessageIcon } from "../../assets/icons/message_icon.svg";
import getAboutPageContent from "../../custom-hooks/getAboutPageContent";
import { Mailto } from "../Mailto/Mailto";
import styles from "./EmailButton.module.css";

const EmailButton = ({ variant }) => {
  const [content, isLoading] = getAboutPageContent();

  const container =
    variant === "primary" ? styles.primaryContainer : styles.secondaryContainer;
  return (
    <div className={styles.mainContainer}>
      <Mailto email={isLoading ? null : content[0].fields.email}>
        <button className={`${styles.button} ${container}`}>
          <MessageIcon />
          <p>{isLoading ? null : content[0].fields.email}</p>
        </button>
      </Mailto>
    </div>
  );
};

export default EmailButton;
