import React from "react";
import { ReactComponent as Left } from "../../assets/Faq/left.svg";
import { ReactComponent as Right } from "../../assets/Faq/right.svg";
import Fade from "react-reveal/Fade";
import MD from "react-markdown";
import styles from "./FaqBlock.module.css";

const FaqBlock = ({ variant, text }) => {
  const container =
    variant === "primary" ? styles.primaryContainer : styles.secondaryContainer;

  return (
    <Fade bottom>
      <div className={`${styles.container} ${container}`}>
        {variant === "primary" ? (
          <Left className={styles.left} />
        ) : (
          <Right className={styles.right} />
        )}
        <MD children={text} />
      </div>
    </Fade>
  );
};

export default FaqBlock;
