import React from "react";
import { Link } from "react-router-dom";
import EmailButton from "../../EmailButton/EmailButton";
import styles from "./SideNav.module.css";

const SideNav = ({ isOpen, setIsOpen }) => {
  const handleClick = () => setIsOpen(false);
  return (
    <div
      className={`${styles.container} ${
        isOpen ? styles.showHeader : styles.hideHeader
      }`}
    >
      <nav>
        <span className={styles.times} onClick={() => setIsOpen(false)}>
          &times;
        </span>
        <ul>
          <Link to="/" onClick={handleClick}>
            <li>About me</li>
          </Link>
          <Link to="/writings" onClick={handleClick}>
            <li>Writings</li>
          </Link>
          <Link to="/projects" onClick={handleClick}>
            <li>Fun Projects</li>
          </Link>
          <Link to="/music" onClick={handleClick}>
            <li>My Music</li>
          </Link>
          <Link to="/investments" onClick={handleClick}>
            <li>Investments</li>
          </Link>
          <Link to="/design-portfolio" onClick={handleClick}>
            <li>Design Portfolio</li>
          </Link>
          <li>
            <EmailButton />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SideNav;
