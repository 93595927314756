import React, { useState } from "react";
import Card from "../../components/Card/Card";
import MD from "react-markdown";
import getDesignPortfolioPageContent from "../../custom-hooks/getDesignPortfolioPageContent";
import useDesignPortfolioPosts from "../../custom-hooks/useDesignPortfolioPosts";
import styles from "./DesignPortfolio.module.css";

const DesignPortfolio = () => {
  const [content, isLoading] = getDesignPortfolioPageContent();
  const [posts] = useDesignPortfolioPosts();
  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) return null;
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.textContainer}>
          <h1>{content[0].fields.heading}</h1>
          <div className={styles.quote}>
            <MD children={content[0].fields.text} />
          </div>
        </div>

        <div className={styles.portfolioProjects}>
          {!isLoading
            ? posts.map((post) => (
                <Card
                  id={post.fields.slug}
                  title={post.fields.title}
                  description={post.fields.description}
                  tag={post.fields.tag}
                  password={post.fields.password}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default DesignPortfolio;
