const client = require("contentful").createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN,
});

// Get all writing posts
const getBlogPosts = () =>
  client
    .getEntries({
      content_type: "writings",
    })
    .then((response) => {
      return response.items;
    });

// Get single writing post
const getSinglePost = (slug) =>
  client
    .getEntries({
      "fields.slug": slug,
      content_type: "writings",
    })
    .then((response) => response.items);

// Get all Design Portfolio posts
const getDesignPortfolioPosts = () =>
  client
    .getEntries({
      content_type: "designPortfolioBlogs",
    })
    .then((response) => {
      return response.items;
    });

// Get single writing post
const getSingleDesignPortfolioPost = (slug) =>
  client
    .getEntries({
      "fields.slug": slug,
      content_type: "designPortfolioBlogs",
    })
    .then((response) => response.items);

// Get all investment posts
const getInvestmentPosts = () =>
  client
    .getEntries({
      content_type: "investmentsBlogs",
    })
    .then((response) => {
      return response.items;
    });

// Get single Investment post
const getSingleInvestmentPost = (slug) =>
  client
    .getEntries({
      "fields.slug": slug,
      content_type: "investmentsBlogs",
    })
    .then((response) => response.items);

// Get About page content
const getAboutPageContent = () =>
  client
    .getEntries({
      content_type: "websiteContent",
    })
    .then((response) => {
      return response.items;
    });

// Get FAQs
const getFaqs = () =>
  client
    .getEntries({
      content_type: "faq",
    })
    .then((response) => {
      return response.items;
    });

// Get Design Portfolio page content
const getDesignPortfolioPageContent = () =>
  client
    .getEntries({
      content_type: "designPortfolio",
    })
    .then((response) => {
      return response.items;
    });

// Get Music page content
const getMusicPageContent = () =>
  client
    .getEntries({
      content_type: "myMusic",
    })
    .then((response) => {
      return response.items;
    });

// Get Writings page content
const getWritingsPageContent = () =>
  client
    .getEntries({
      content_type: "writingsPage",
    })
    .then((response) => {
      return response.items;
    });

// Get Investments page content
const getInvestmentsPageContent = () =>
  client
    .getEntries({
      content_type: "investments",
    })
    .then((response) => {
      return response.items;
    });

// Get Side Projects page content
const getSideProjectsPageContent = () =>
  client
    .getEntries({
      content_type: "sideProjects",
    })
    .then((response) => {
      return response.items;
    });

// Get Side Projects List
const getSideProjectsList = () =>
  client
    .getEntries({
      content_type: "sideProjectsList",
    })
    .then((response) => {
      return response.items;
    });

export {
  getBlogPosts,
  getSinglePost,
  getDesignPortfolioPosts,
  getSingleDesignPortfolioPost,
  getInvestmentPosts,
  getSingleInvestmentPost,
  getAboutPageContent,
  getDesignPortfolioPageContent,
  getMusicPageContent,
  getWritingsPageContent,
  getSideProjectsPageContent,
  getSideProjectsList,
  getInvestmentsPageContent,
  getFaqs,
};
