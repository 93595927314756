import React from "react";
import Project from "../../components/Project/Project";
import getSideProjectsPageContent from "../../custom-hooks/getSideProjectsPageContent";
import getSideProjectsList from "../../custom-hooks/getSideProjectsList";
import styles from "./Projects.module.css";

const Projects = () => {
  const [content, isLoading] = getSideProjectsPageContent();
  const [list] = getSideProjectsList();
  return (
    <div className={styles.container}>
      {isLoading ? null : (
        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <h1>{content[0].fields.heading}</h1>
            <p>{content[0].fields.text}</p>
          </div>
          <div className={styles.projectsContainer}>
            {list.map((project) => (
              <Project
                img={project.fields.image.fields.file.url}
                title={project.fields.title}
                description={project.fields.description}
                index={project.sys.id}
                link={project.fields.projectLink}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;
