import React, { useState } from "react";
import { useHistory } from "react-router";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import BackDrop from "./BackDrop/BackDrop";
import styles from "./Card.module.css";
import PasswordCard from "./PasswordCard/PasswordCard";

const Card = ({ title, description, tag, id, password, isOpen, setIsOpen }) => {
  const history = useHistory();

  const handleClick = () => {
    if (password) {
      setIsOpen(true);
    } else {
      history.push(`design-portfolio/${id}`);
    }
  };
  return (
    <>
      <div className={styles.container} key={id} onClick={handleClick}>
        <div>
          <div className={styles.titleContainer}>
            <h5>{title}</h5>
            {password ? <LockIcon /> : null}
          </div>
          <p>{description}</p>
        </div>
        <div className={styles.more}>
          <p>Read more</p>
          <small>{tag}</small>
        </div>
        {isOpen && password ? <PasswordCard password={password} id={id} /> : null}
      </div>
      <BackDrop isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default Card;
