import React from "react";
import Subscribe from "../../components/Subscribe/Subscribe";
import { Link } from "react-router-dom";
import useInvestmentPosts from "../../custom-hooks/useInvestmentPosts";
import getInvestmentsPageContent from "../../custom-hooks/getInvestmentsPageContent";
import styles from "./Investments.module.css";

const Investments = () => {
  const [posts, isLoading] = useInvestmentPosts();
  const [content, isContentLoading] = getInvestmentsPageContent();

  return (
    <div className={styles.container}>
      {isContentLoading ? null : (
        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <div className={styles.firstRow}>
              <h1>{content[0].fields.heading}</h1>
              <div className={styles.subscribeContainerWeb}>
                <Subscribe />
              </div>
            </div>
            <p>{content[0].fields.text}</p>
            <div className={styles.subscribeContainerMob}>
              <Subscribe />
            </div>
            <ul>
              {!isLoading ? (
                posts.map((post) => (
                  <Link
                    to={`investments/${post.fields.slug}`}
                    key={post.fields.slug}
                  >
                    <li>{post.fields.title}</li>
                  </Link>
                ))
              ) : (
                null
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default Investments;
