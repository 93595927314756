import React, { useState, useEffect } from "react";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import getAboutPageContent from "../../custom-hooks/getAboutPageContent";
import { useLocation } from "react-router-dom";

const Layout = ({ children }) => {
  const [content, isLoading] = getAboutPageContent();
  const location = useLocation();
  const [blogParam, setBlogParam] = useState("");
  const [writingsParam, setWritingsParam] = useState("");
  const [investmentsParam, setInvestmentsParam] = useState("");

  useEffect(() => {
    const SlicedLocation = location.pathname.slice(0, 18);
    const SlicedLocationTwo = location.pathname.slice(0, 10);
    const SlicedLocationThree = location.pathname.slice(0, 13);

    if (SlicedLocation === "/design-portfolio/") {
      setBlogParam(location.pathname.slice(18));
    } else if (SlicedLocationTwo === "/writings/") {
      setWritingsParam(location.pathname.slice(10));
    } else if (SlicedLocationThree === "/investments/") {
      setInvestmentsParam(location.pathname.slice(13));
    }
  }, [location]);

  if (isLoading) return null;

  return (
    <>
      <Header
        name={content[0].fields.name}
        myImage={content[0].fields.myImage.fields.file.url}
        blogParam={blogParam}
        writingsParam={writingsParam}
        investmentsParam={investmentsParam}
      />
      {children}
      <Footer
        name={content[0].fields.normalName}
        myImage={content[0].fields.myImage.fields.file.url}
      />
    </>
  );
};

export default Layout;
