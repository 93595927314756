import React from "react";
import FaqBlock from "../../../components/FaqBlock/FaqBlock";
import styles from "./FAQs.module.css";
import getFaqs from "../../../custom-hooks/getFaqs";

const FAQs = () => {
  const [content, isLoading] = getFaqs();

  if (isLoading) return null;

  if (content) {
    function compare(a, b) {
      if (a.fields.order < b.fields.order) {
        return -1;
      }
      if (a.fields.order > b.fields.order) {
        return 1;
      }
      return 0;
    }

    const sorted = content.sort(compare);
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {content
          ? content.map((item) => (
              <FaqBlock
                variant={item.fields.isQuestion ? "primary" : "secondary"}
                text={item.fields.text}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default FAQs;
