import { useEffect, useState } from "react";

import { getSideProjectsList } from "../contentful";

const promise = getSideProjectsList();

export default function usePosts() {
  const [list, setList] = useState([]);
  const [isListLoading, setIsLoading] = useState(true);

  useEffect(() => {
    promise.then((content) => {
      setList(content);
      setIsLoading(false);
    });
  }, []);

  return [list, isListLoading];
}
