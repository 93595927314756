import React from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import styles from "./PasswordCard.module.css";

const PasswordCard = ({ password, id }) => {
  const [pass, setPass] = useState("");
  const [error, setError] = useState(false);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pass === password) {
      history.push(`design-portfolio/${id}`);
    } else {
      setError(true);
    }
  };
  const handleChange = (e) => {
    setError(false);
    setPass(e.target.value);
  };
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <h3>To view this content Password is required. </h3>
        <LockIcon />
      </div>
      <div className={styles.inputContainer}>
        <label>Please enter the password here</label>
        <form className={styles.inputWrapper} onSubmit={handleSubmit}>
          <input
            type="password"
            alt="password"
            value={pass}
            onChange={handleChange}
          />
          <button>Enter</button>
        </form>
      </div>
      {error ? <p className={styles.error}>Incorrect Password</p> : null}
      <p>If you dont have one, please contact me.</p>
    </div>
  );
};

export default PasswordCard;
