import React from 'react'
import styles from "./BackDrop.module.css"

const BackDrop = ({ isOpen, setIsOpen }) => {
    const containerClass = isOpen ? styles.container : styles.hide
    return (
        <div className={containerClass} onClick={() => setIsOpen(false)}></div>
    )
}

export default BackDrop
