import React from "react";
import EmailButton from "../../EmailButton/EmailButton";
import { ReactComponent as Triangle } from "../../../assets/icons/triangle2.svg";
import { ReactComponent as TwitterIcon } from "../../../assets/icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "../../../assets/icons/linkedin.svg";
import { useLocation } from "react-router-dom";
import styles from "./Footer.module.css";

var d = new Date();
var currentYear = d.getFullYear();

const Footer = ({ name, myImage }) => {
  const location = useLocation();

  return (
    <footer className={`${styles.mainFooter}`}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img src={myImage} alt="yusuf-hassan-profile-pic" />
          <p>
            <span>
              © {currentYear} {name}
            </span>
            All rights reserved.
          </p>
        </div>
        <div className={styles.socialContainer}>
          <EmailButton variant="secondary" />
          <a href="https://twitter.com/adebayohassan">
            <TwitterIcon className={styles.twitterIcon} />
          </a>
          <a href="https://www.linkedin.com/in/yusufadehassan/">
            <LinkedinIcon className={styles.linkedinIcon} />
          </a>
        </div>
      </div>
      {location.pathname === "/" && (
        <div
          className={styles.circle}
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <Triangle />
        </div>
      )}
    </footer>
  );
};

export default Footer;
