import React, { useState } from "react";
import Song from "../../components/Song/Song";
import getMusicPageContent from "../../custom-hooks/getMusicPageContent";
import styles from "./Music.module.css";

const Music = () => {
  const [content, isLoading] = getMusicPageContent();

  const [hoverPlayState, setHoverPlayState] = useState(false);
  const [playAudio, setPlayAudio] = useState(false);
  const [pauseAudio, setPauseAudio] = useState(false);

  const startSong = (e) => {
    let allAudios = document.getElementsByTagName("audio");
    Array.from(allAudios).map((aud) => {
      aud.pause()
    });
    
    setPauseAudio(e.target.id)
    setHoverPlayState("");
    setPlayAudio("")

    let audio = document.getElementById(`audio${e.target.id}`);
    audio.play();
  };

  const stopSong = (e) => {
    let audio = document.getElementById(`audio${e.target.id}`);
    audio.pause();
    setPauseAudio(false)
    if (audio.currentTime >= 0 && audio.paused && audio.currentTime !== audio.duration) {
      setPlayAudio(e.target.id)
    } else {
      setPlayAudio(false)
    }
  };

  return (
    <div className={styles.container}>
      {isLoading ? null : (
        <div className={styles.wrapper}>
          <div className={styles.textContainer}>
            <h1>{content[0].fields.heading}</h1>
            <p>{content[0].fields.text}</p>
          </div>
          {/* MUSIC CONTAINER */}
          <div className={styles.musicCategories}>
            <div className={`${styles.category} ${styles.worldMusic}`}>
              <div className={styles.subTextContainer}>
                <h5>{content[0].fields.myWorldMusicHeading}</h5>
                <p>{content[0].fields.myWorldMusicText}</p>
              </div>
              <div className={styles.musicContainer}>
                <p>Song Name</p>
                {content[0].fields.myWorldMusic.map((song, index) => (
                  <Song
                    index={index + 1}
                    title={song.fields.title}
                    file={song.fields.file.url}
                    startSong={startSong}
                    stopSong={stopSong}
                    setHoverPlayState={setHoverPlayState}
                    hoverPlayState={hoverPlayState}
                    playAudio={playAudio}
                    pauseAudio={pauseAudio}
                    setPauseAudio={setPauseAudio}
                  />
                ))}
              </div>
            </div>

            <div className={`${styles.category} ${styles.religousMusic}`}>
              <div className={styles.subTextContainer}>
                <h5>{content[0].fields.religiousMusicHeading}</h5>
                <p>{content[0].fields.religiousMusicText}</p>
              </div>
              <div className={styles.musicContainer}>
                <p>Song Name</p>
                {content[0].fields.religiousMusic.map((song, index) => (
                  <Song
                    index={index + 1}
                    title={song.fields.title}
                    file={song.fields.file.url}
                    startSong={startSong}
                    stopSong={stopSong}
                    setHoverPlayState={setHoverPlayState}
                    hoverPlayState={hoverPlayState}
                    playAudio={playAudio}
                    pauseAudio={pauseAudio}
                    setPauseAudio={setPauseAudio}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Music;
