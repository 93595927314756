import React, { useState } from "react";
import { ReactComponent as MenuIcon } from "../../../assets/icons/menu-icon.svg";
import { Link, useLocation, useHistory } from "react-router-dom";
import EmailButton from "../../EmailButton/EmailButton";
import styles from "./Header.module.css";
import SideNav from "../SideNav/SideNav";
import Backdrop from "../Backdrop/Backdrop";

const Header = ({ name, myImage, blogParam, writingsParam, investmentsParam }) => {
  const location = useLocation();
  const history = useHistory();

  const container =
    location.pathname === "/"
      ? styles.primaryHeader
      : location.pathname === `/design-portfolio/${blogParam}`
      ? styles.tertiaryHeader
      : styles.secondaryHeader;

  const menuClass =
    location.pathname === "/" ? styles.primaryMenu : styles.secondaryMenu;

  const [isOpen, setIsOpen] = useState(false);
  return (
    <header className={`${styles.mainHeader} ${container}`}>
      <div className={styles.container}>
        <div className={styles.logoContainer} onClick={() => history.push("/")}>
          <img src={myImage} alt="yusuf-profile-pic" />
          <p>{name}</p>
        </div>
        <nav>
          <ul>
            <Link to="/">
              <li>About me</li>
              {location.pathname === "/" && (
                <span className={styles.hoverDot} />
              )}
            </Link>
            <Link to="/writings">
              <li>Writings</li>
              {(location.pathname === "/writings" ||
                location.pathname === `/writings/${writingsParam}`) && (
                <span className={styles.hoverDot} />
              )}
            </Link>
            <Link to="/projects">
              <li>Fun Projects</li>
              {location.pathname === "/projects" && (
                <span className={styles.hoverDot} />
              )}
            </Link>
            <Link to="/music">
              <li>My Music</li>
              {location.pathname === "/music" && (
                <span className={styles.hoverDot} />
              )}
            </Link>
            <Link to="/investments">
              <li>Investments</li>
              {(location.pathname === "/investments" ||
                location.pathname === `/investments/${investmentsParam}`) && (
                <span className={styles.hoverDot} />
              )}
            </Link>
            <Link to="/design-portfolio">
              <li>Design Portfolio</li>
              {location.pathname === "/design-portfolio" && (
                <span className={styles.hoverDot} />
              )}
            </Link>
          </ul>
        </nav>
        <div className={styles.emailButtonContainer}>
          <EmailButton
            variant={`${
              location.pathname === `/design-portfolio/${blogParam}` ||
              location.pathname === "/"
                ? "primary"
                : "secondary"
            }`}
          />
        </div>
        <MenuIcon
          onClick={() => setIsOpen(true)}
          className={`${styles.menuIcon} ${menuClass}`}
        />
        <Backdrop isOpen={isOpen} setIsOpen={setIsOpen} />
        <SideNav isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </header>
  );
};

export default Header;
