import React from "react";
import styles from "./Backdrop.module.css";

const Backdrop = ({ isOpen, setIsOpen }) => {
  return (
    <div
      className={`${styles.container} ${isOpen ? styles.show : styles.hide}`}
      onClick={() => setIsOpen(false)}
    />
  );
};

export default Backdrop;
